<template>
  <div class="plan-add">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >资源库管理</el-breadcrumb-item>
      <el-breadcrumb-item to='/zykgl/plan'>方案管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{'查看'+'方案'}}</el-breadcrumb-item>
    </el-breadcrumb>-->
    <div class="btn preview">
      <el-button type="primary" @click="showPre">预览方案</el-button>
    </div>
    <el-tabs v-model="tabs" :before-leave="handleTab">
      <!-- --------------------------基本信息-------------------- -->
      <el-tab-pane label="基本信息" name="basic">
        <div class="search-list">
          <el-form
            :disabled="basicFlag"
            v-loading="dataListLoading"
            label-width="140"
            class="el-form-info"
          >
            <el-form-item label="方案名称">
              {{ planName }}
              <!-- <el-input v-model="planName" placeholder="请输入方案名称" style='width:50%'></el-input> -->
            </el-form-item>
            <el-form-item label="持续时间">{{ executionDay }}</el-form-item>
            <el-form-item label="方案简介">{{ context }}</el-form-item>
            <!-- <el-form-item>
              <el-button type='primary' @click='save'>保存</el-button>
              <el-button @click='back'>取消</el-button>
            </el-form-item>-->
          </el-form>
          <!-- <div class="label">方案名称：</div> -->
        </div>
      </el-tab-pane>
      <!-- --------------------------基本信息 /-------------------- -->
      <!-- --------------------------一次性活动 -------------------- -->
      <el-tab-pane label="一次性活动" name="once">
        <div class="once">
          <div class="left">
            <!-- <el-button type='primary' style='width:100%;margin-bottom:20px' @click='addActivity'>新增关键活动</el-button> -->
            <!-- <template></template> -->
            <div
              class="card"
              v-for="(card, index) in formList"
              :key="index"
              v-show="card.leftShow"
              @click.stop="showRight(card)"
            >
              <span class="text">{{ card.activityName }}</span>
              <!-- <el-button style="float: right; padding: 3px 0;color:#f56c6c"  type="text" @click.stop='delActivity(card)'>删除</el-button> -->
            </div>
          </div>
          <div class="right">
            <el-form
              v-for="(form, index) in clickSortList"
              :key="index"
              ref="form"
              :model="form"
              label-width="220px"
              class="form"
            >
              <div class="line"></div>
              <div class="content">
                <div class="lie">
                  <el-form-item
                    label="活动名"
                    prop="activityName"
                    style="flex-basis: 50%"
                  >
                    {{ form.activityName }}
                    <!-- <el-input v-model.trim="form.activityName" placeholder="请填写活动名" class='wd300'></el-input> -->
                  </el-form-item>
                  <el-form-item
                    label="活动类型"
                    prop="activityType"
                    style="flex-basis: 50%"
                  >
                    {{ form.activityTypeName }}
                    <!-- <el-select
                        placeholder="请选择活动类型"
                        v-model="form.activityType"
                        clearable
                        class='wd300'
                        @change='changeActivityType(index)'
                      >
                        <el-option
                          v-for="(item,index) in typeList"
                          :key="index"
                          :value="item.value"
                          :label="item.name"
                        ></el-option>
                    </el-select>-->
                  </el-form-item>
                </div>
                <div class="lie">
                  <el-form-item label="前序活动" style="flex-basis: 50%">{{
                    form.beforeActivityName
                  }}</el-form-item>
                  <el-form-item
                    label="距离前序活动时间（天）"
                    prop="beforeActivityTime"
                    style="flex-basis: 50%"
                    >{{ form.beforeActivityTime }}</el-form-item
                  >
                </div>
                <!-- <el-form-item label="第" prop='activityStart'>
                    {{form.activityStart}}&nbsp&nbsp&nbsp天
                </el-form-item>-->
                <div class="lie">
                  <el-form-item
                    label="执行时间"
                    prop="executionTime"
                    style="flex-basis: 50%"
                  >
                    {{ form.executionTime }}
                    <!-- <el-time-picker class='wd300'
                        v-model="form.executionTime"
                        placeholder="请选择执行时间"
                        value-format='HH:mm:ss'
                        >
                    </el-time-picker>-->
                  </el-form-item>
                  <el-form-item
                    label="活动排序"
                    prop="orderNum"
                    style="flex-basis: 50%"
                  >
                    {{ form.orderNum }}
                    <!-- <el-input v-model="form.orderNum" type='number' class='wd300' oninput="value=value.replace(/[^0-9.]/g,'')"></el-input> -->
                  </el-form-item>
                </div>
                <div v-if="form.activityType == 6 && form.startDay" class="lie">
                  <el-form-item label="窗口期">
                    - {{ form.startDay }}天 &nbsp; ~ &nbsp; +{{ form.endDay }}天
                  </el-form-item>
                </div>
                <el-form-item label="备注">
                  {{ form.activityRemarks }}
                  <!-- <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入备注"
                    v-model="form.activityRemarks">
                  </el-input>-->
                </el-form-item>
              </div>
              <div class="main-cnt">
                <div class="title">
                  <div class="shu"></div>
                  <div class="text">主题内容</div>
                </div>
                <div
                  class="cnt-input"
                  v-if="
                    (form.activityType != 1) &
                    (form.activityType != 4) &
                    (form.activityType != 5) &
                    (form.activityType != 6)
                  "
                >
                  <el-form-item label="活动描述">
                    {{ form.activityDescription }}
                    <!-- <el-input type='textarea' placeholder="请填写活动描述" v-model="form.activityDescription"></el-input> -->
                  </el-form-item>
                </div>
                <template v-else>
                  <div
                    class="other-type"
                    v-if="!form.flag"
                    @click="addMainCnt(form, index)"
                  >
                    + 新增主题内容
                  </div>
                  <!-- 如果有内容 -->
                  <template v-else>
                    <!-- 第一种活动类型 服药的列表 -->
                    <template v-if="form.activityType == 1">
                      <el-table :data="form.medicineList">
                        <el-table-column
                          prop="drugsSimpleName"
                          label="药品简称"
                        ></el-table-column>
                        <el-table-column
                          prop="drugsUseTypeName"
                          label="用法"
                        ></el-table-column>
                        <el-table-column
                          prop="drugsBaseDose"
                          label="用量"
                        ></el-table-column>
                        <el-table-column
                          prop="drugsUseUnitName"
                          label="单位"
                        ></el-table-column>
                        <!-- <el-table-column label='操作' header-align="center" align="center" fixed="right"> 
                        <template slot-scope="scope">
                          <el-button type="text" size="small" @click='deleteMed(scope.row,index)'>删除</el-button>
                        </template>
                        </el-table-column>-->
                      </el-table>
                    </template>
                    <template v-else-if="form.activityType == 4">
                      <el-table :data="form.missionaryWorks">
                        <el-table-column
                          prop="workTypeName"
                          label="宣教分类"
                        ></el-table-column>
                        <el-table-column
                          prop="workName"
                          label="宣教名称"
                        ></el-table-column>
                        <!-- <el-table-column label='操作' header-align="center" align="center" > 
                          <template slot-scope="scope">
                            <el-button type="text" size="small" @click='deleteMed(scope.row,index)'>删除</el-button>
                          </template>
                        </el-table-column>-->
                      </el-table>
                    </template>
                    <!-- <template v-else-if="form.activityType==5||form.activityType==6">
                      <el-table :data="form.indexLibraries">
                        <el-table-column prop="libraryTypeName" label="问卷分类"></el-table-column>
                        <el-table-column prop="libraryName" label="问卷名称"></el-table-column>
                 
                      </el-table>
                    </template> -->
                    <template
                      v-else-if="
                        form.activityType == 5 || form.activityType == 6
                      "
                    >
                      <el-table :data="form.indexLibraries">
                        <!-- <el-table-column prop='libraryTypeName' label='随访分类'></el-table-column> -->
                        <el-table-column
                          prop="tableName"
                          label="问卷名称"
                        ></el-table-column>
                        <!-- <el-table-column prop='tableName' label='问卷名称2'></el-table-column> -->
                      </el-table>
                    </template>
                  </template>
                </template>
              </div>
              <div class="mt20 mb20" style="text-align: center">
                <el-button @click="cancelActivity(index)">取消</el-button>
                <!-- <el-button type='primary' @click='submit(index,1)'>保存</el-button> -->
              </div>
              <choose-medicine
                v-show="form.dialog"
                ref="chooseMed"
                @medicine="getMed($event, index)"
              ></choose-medicine>
              <choose-question
                v-show="form.qsDialog"
                ref="chooseQs"
                @chooseQs="getOther($event, index)"
                :type="form.activityType"
              ></choose-question>
            </el-form>
          </div>
        </div>
        <!-- <choose-medicine ref='chooseMed2'></choose-medicine> -->
      </el-tab-pane>
      <!-- --------------------------一次性活动 /-------------------- -->
      <el-tab-pane label="周期性活动" name="circle">
        <div class="circle">
          <div class="left">
            <!-- <el-button type='primary' style='width:100%;margin-bottom:20px' @click='addActivity'>新增关键活动</el-button> -->
            <div
              class="card"
              v-for="(card, index) in circleList"
              :key="index"
              v-show="card.leftShow"
              @click.stop="showRight2(card)"
            >
              <span class="text">{{ card.activityName }}</span>
              <!-- <el-button style="float: right; padding: 3px 0;color:#f56c6c"  type="text" @click.stop='delActivity(card)'>删除</el-button> -->
            </div>
          </div>
          <div class="right">
            <el-form
              v-for="(form, index) in clickSortList2"
              :key="index"
              ref="form"
              :model="form"
              label-width="80px"
              class="form"
            >
              <div class="line"></div>
              <div class="content">
                <div class="lie">
                  <el-form-item
                    label="活动名"
                    prop="activityName"
                    style="flex-basis: 50%"
                  >
                    {{ form.activityName }}
                    <!-- <el-input v-model.trim="form.activityName" placeholder="请填写活动名" class='wd300'></el-input> -->
                  </el-form-item>
                  <el-form-item
                    label="活动类型"
                    prop="activityType"
                    style="flex-basis: 50%"
                  >
                    {{ form.activityTypeName }}
                    <!-- <el-select
                        placeholder="请选择活动类型"
                        v-model="form.activityType"
                        clearable
                        class='wd300'
                        @change='changeActivityType(index)'
                      >
                        <el-option
                          v-for="(item,index) in typeList"
                          :key="index"
                          :value="item.value"
                          :label="item.name"
                        ></el-option>
                    </el-select>-->
                  </el-form-item>
                </div>
                <div class="lie">
                  <el-form-item
                    label="频率"
                    prop="activityRate"
                    style="flex-basis: 50%"
                  >
                    {{ form.activityRateName }}
                    <!-- <el-select
                        placeholder="请选择频率"
                        v-model="form.activityRate"
                        clearable
                        class='wd300'
                      >
                        <el-option
                          v-for="(item,index) in rateList"
                          :key="index"
                          :value="item.value"
                          :label="item.name"
                        ></el-option>
                    </el-select>-->
                  </el-form-item>
                  <div style="display: flex; flex-basis: 50%">
                    <el-form-item label="每" prop="activityStart">
                      {{ form.activityFrequency }}&nbsp&nbsp&nbsp{{
                        form.activityRateName
                      }}
                      <!-- <el-input v-model='form.activityStart' class='wd100' type='number'  oninput="value=value.replace(/[^0-9.]/g,'')"></el-input> -->
                    </el-form-item>
                    <el-form-item
                      label="第"
                      prop="executionDay"
                      v-if="form.activityRate !== 1"
                    >
                      {{ form.activityStart }}&nbsp&nbsp&nbsp天
                      <!-- <el-input class='wd100' type='number' v-model="form.executionDay"  oninput="value=value.replace(/[^0-9.]/g,'')"></el-input> -->
                    </el-form-item>
                  </div>
                </div>
                <div class="lie">
                  <el-form-item
                    label="执行时间"
                    prop="executionTime"
                    style="flex-basis: 50%"
                  >
                    {{ form.executionTime }}
                    <!-- <el-time-picker class='wd300'
                        v-model="form.executionTime"
                        placeholder="请选择执行时间"
                        value-format='HH:mm:ss'
                        >
                    </el-time-picker>-->
                  </el-form-item>
                  <el-form-item
                    label="活动排序"
                    prop="orderNum"
                    style="flex-basis: 50%"
                  >
                    {{ form.orderNum }}
                    <!-- <el-input v-model="form.orderNum" type='number' class='wd300'  oninput="value=value.replace(/[^0-9.]/g,'')"></el-input> -->
                  </el-form-item>
                </div>
                <div v-if="form.activityType == 6 && form.startDay" class="lie">
                  <el-form-item label="窗口期">
                    - {{ form.startDay }}天 &nbsp; ~ &nbsp; +{{ form.endDay }}天
                  </el-form-item>
                </div>
                <el-form-item label="备注">
                  {{ form.activityRemarks }}
                  <!-- <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入备注"
                    v-model="form.activityRemarks">
                  </el-input>-->
                </el-form-item>
              </div>
              <div class="main-cnt">
                <div class="title">
                  <div class="shu"></div>
                  <div class="text">主题内容</div>
                </div>
                <div
                  class="cnt-input"
                  v-if="
                    (form.activityType != 1) &
                    (form.activityType != 4) &
                    (form.activityType != 5) &
                    (form.activityType != 6)
                  "
                >
                  <el-form-item label="活动描述">
                    {{ form.activityDescription }}

                    <!-- <el-input type='textarea' placeholder="请填写活动描述" v-model="form.activityDescription"></el-input> -->
                  </el-form-item>
                </div>
                <template v-else>
                  <div
                    class="other-type"
                    v-if="!form.flag"
                    @click="addMainCnt(form, index)"
                  >
                    + 新增主题内容
                  </div>
                  <!-- 如果有内容 -->
                  <template v-else>
                    <!-- 第一种活动类型 服药的列表 -->
                    <template v-if="form.activityType == 1">
                      <el-table :data="form.medicineList">
                        <el-table-column
                          prop="drugsSimpleName"
                          label="药品简称"
                        ></el-table-column>
                        <el-table-column
                          prop="drugsUseTypeName"
                          label="用法"
                        ></el-table-column>
                        <el-table-column
                          prop="drugsBaseDose"
                          label="用量"
                        ></el-table-column>
                        <el-table-column
                          prop="drugsUseUnitName"
                          label="单位"
                        ></el-table-column>
                        <!-- <el-table-column label='操作' header-align="center" align="center" fixed="right"> 
                        <template slot-scope="scope">
                          <el-button type="text" size="small" @click='deleteMed(scope.row,index)'>删除</el-button>
                        </template>
                        </el-table-column>-->
                      </el-table>
                    </template>
                    <template v-else-if="form.activityType == 4">
                      <el-table :data="form.missionaryWorks">
                        <el-table-column
                          prop="workTypeName"
                          label="宣教分类"
                        ></el-table-column>
                        <el-table-column
                          prop="workName"
                          label="宣教名称"
                        ></el-table-column>
                        <!-- <el-table-column label='操作' header-align="center" align="center" > 
                          <template slot-scope="scope">
                            <el-button type="text" size="small" @click='deleteMed(scope.row,index)'>删除</el-button>
                          </template>
                        </el-table-column>-->
                      </el-table>
                    </template>
                    <template
                      v-else-if="
                        form.activityType == 5 || form.activityType == 6
                      "
                    >
                      <el-table :data="form.indexLibraries">
                        <!-- <el-table-column prop='libraryTypeName' label='随访分类'></el-table-column> -->
                        <el-table-column
                          prop="tableName"
                          label="问卷名称"
                        ></el-table-column>
                        <!-- <el-table-column prop='tableName' label='问卷名称2'></el-table-column> -->
                      </el-table>
                    </template>
                  </template>
                </template>
              </div>
              <div class="mt20 mb20" style="text-align: center">
                <el-button @click="cancelActivity2(index)">取消</el-button>
                <!-- <el-button type='primary' @click='submit(index,2)'>保存</el-button> -->
              </div>
              <!-- <choose-medicine v-show='form.dialog' ref='chooseMed' @medicine='getMed($event,index)'></choose-medicine> -->
              <!-- <choose-question v-show='form.qsDialog' ref='chooseQs' @chooseQs='getOther($event,index)' :type='form.activityType'></choose-question> -->
              <!-- <choose-question v-if='chooseQsDialog' ref='`chooseQs${index}`' ></choose-question> -->
            </el-form>
          </div>
        </div>
        <!-- <circle1  @submit2='circlePush' :entityId='diseases'  :programInfoId='programInfoId' :typeList='typeList' :circleList='circleList' :edit='edit'></circle1> -->
      </el-tab-pane>
      <!-- 预览 -->
      <!-- <el-tab-pane label="1">123</el-tab-pane> -->
      <preview
        v-show="preview"
        ref="preview"
        :list="allList"
        :executionDay="executionDay"
      ></preview>
    </el-tabs>
  </div>
</template>
<script>
import chooseMedicine from "../components/chooseMedicine";
import chooseQuestion from "../components/chooseQuestion";
import preview from "../components/preview.vue";
import circle1 from "../components/circle.vue";
import Qs from "qs";
export default {
  name: "planInfo",
  components: {
    chooseMedicine,
    chooseQuestion,
    circle1,
    preview,
  },
  data() {
    return {
      rateList: [], //频率列表
      preview: false, //预览
      dataListLoading: false,
      tabs: "basic",
      // flag0:false,
      basicFlag: false, //基础信息
      planName: "",
      diseases: "", //病种
      diseasesList: [], //病种列表
      typeList: [], //活动类型列表
      // chooseMedDialog:false,
      // chooseQsDialog:false,
      formList: [
        {
          executionTime: "",
          activityName: "",
          startDay: "",
          endDay: "",

          activityType: "", //活动类型
          activityDescription: "",
          activityStart: "", //第_天
          activityRemarks: "", //备注
          executionTime: "", //执行时间
          orderNum: "", //活动排序
          dialog: false, //服药dialog
          qsDialog: false, //qsDialog
          flag: false, //是否有主题内容
          show: true, //右侧展示
          leftShow: false, //左侧是否展示
          medicineList: [],
          missionaryWorks: [],
          indexLibraries: [],
          new: true,
        },
      ],
      clickSortList: [], //点击后的排序
      programInfoId: "", //方案id
      dataRules: {
        activityName: [
          { required: true, message: "活动名不能为空", trigger: "blur" },
        ],
        activityType: [
          { required: true, message: "请选择活动类型", trigger: "change" },
        ],
        executionTime: [
          { required: true, message: "执行时间不能为空", trigger: "blur" },
        ],
        orderNum: [
          { required: true, message: "活动排序不能为空", trigger: "blur" },
        ],
        activityStart: [
          { required: true, message: "时间不能为空", trigger: "blur" },
        ],
      },
      circleList: [],
      clickSortList2: [],
      edit: false, //是否为编辑页面
      allList: [], //所有方案活动
      executionDay: "",
      context: "",
    };
  },
  created() {},
  async mounted() {
    // await this.getRateList()
    // await this.getDiseasesList()
    await this.getTypeList();
    let query = this.$route.query;
    if (query.id) {
      // this.edit = true
      this.dataListLoading = true;
      await this.init(query.id);
    }
  },
  methods: {
    async getRateList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '频率',
		
		},
       
      });
      // console.log(res,'res')
      this.rateList = res.data;
    },
    // 编辑时初始化数据
    async init(id) {
      // console.log(id);
      const { data: res } = await this.$http({
        url: this.$http.adornUrl("/programInfo/getProgramInfo"),
        method: "post",
        params: { id: id },
      });
      // console.log(res,'init res');
      if (res.status) {
        this.planName = res.data.programName;
        this.executionDay = res.data.executionDay;
        this.context = res.data.context;
        // this.diseasesList.forEach(v=>{
        //   if(v.value==res.data.enertyId){
        //     this.diseases = v.name
        //   }
        // })
        this.programInfoId = res.data.id;
        await this.getActivityList();
      } else {
        this.$message.error("获取信息失败，请重试");
        this.dataListLoading = false;
      }
    },
    changeActivityType(index) {
      this.formList[index].flag = false;
    },
    async getActivityList() {
      const { data: result } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/programActivity/list"),
        method: "post",
        data: { stringParam2: this.programInfoId, intParam1: 999 },
      });
      if (result.status) {
        result.data.forEach((v) => {
          for (let index = 0; index < result.data.length; index++) {
            const ele = result.data[index];
            if (ele.beforeActivityId == v.id) {
              ele.beforeActivityName = v.activityName;
            }
          }
          v.leftShow = true;
          v.show = false;
          v.dialog = false;
          v.qsDialog = false;
          //问卷做了变动 字段修改
          console.log(v, v.indexLibraries);
          if (v.vfTableInfos) {
            v.indexLibraries = v.vfTableInfos;
          }
          // if (!v.indexLibraries.length) {
          //   console.log(v.indexLibraries, v.vfTableInfo);
          //   v.indexLibraries = [v.vfTableInfos];
          //   v.indexLibraries[0].libraryName = v.vfTableInfos.tableName;
          //   v.indexLibraries[0].libraryTypeName = v.vfTableInfos.tableModelName;
          // }
          // 如果有主题内容--药物信息
          if (v.drugsManageId || v.missionaryWorkId || v.followUpId) {
            v.flag = true;
            if (v.drugsManages) {
              v.medicineList = v.drugsManages;
            }
          } else {
            v.flag = false;
            v.medicineList = [];
          }
          //如果有前序活动
          // res.data.forEach(v2=>{
          //   if(v.beforeActivityId==v2.id){
          //     v.beforeActivityName = v2.activityName
          //   }
          // })
          // if(v.activityRate){
          //   this.rateList.forEach(rate=>{
          //     if(rate.value == v.activityRate){

          //     }
          //   })
          // }
          // 如果有主题内容--宣教信息--之前用了其他字段名的列表来装 后面就跟拿到的列表名保持一致好了
          // if(){
          //   v.flag = true
          // }else{
          //   v.flag = false
          // }
        });
        result.data.sort((a, b) => {
          return b.orderNum - a.orderNum;
        });
        let once = result.data.filter((v) => {
          return v.activityMode == 1;
        });
        let circle = result.data.filter((v) => {
          return v.activityMode == 2;
        });
        this.allList = result.data;
        this.circleList = circle;
        this.formList = once;
        this.dataListLoading = false;
      }
    },
    back() {
      this.$router.back();
    },
    // 新增活动
    addActivity() {
      this.formList.push({
        executionTime: "",
        activityName: "",
        activityType: "", //活动类型
        activityDescription: "",
        activityStart: "", //第_天
        activityRemarks: "", //备注
        executionTime: "", //执行时间
        orderNum: "", //活动排序
        dialog: false,
        qsDialog: false,
        flag: false,
        show: true,
        leftShow: false,
        medicineList: [],
        missionaryWorks: [],
        indexLibraries: [],
        new: true, //是否新增
      });
      // console.log(this.$refs,'refs add');
    },
    // 删除活动
    async delActivity(card) {
      let id = card.id;
      this.$confirm("确定进行[删除活动]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          // this.dataListSelections.forEach(v=>{
          //   ids.push(v.id)
          // })
          // const id = ids.join(`,`)

          const { data: res } = await this.$http({
            url: this.$http.adornUrl(`/programActivity/delete?ids=${id}`),
            method: "post",
          });
          console.log(res);
          if (res.status) {
            this.$message.success("删除活动成功");
            this.getActivityList();
            // this.getDataList()
            // let arr = this.formList.filter((v)=> v.id!==card.id)
            // this.formList = arr
          }
        })
        .catch(() => {});
    },
    // 保存方案基本信息
    async save() {
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      const user = JSON.parse(localStorage.getItem("userInfo"));
      if (!this.planName) {
        this.$message.error("请输入方案名称");
        return;
      }
      if (!this.diseases) {
        this.$message.error("请选择病种");
        return;
      }
      let obj = {
        enertyId: this.diseases,
        programName: this.planName,
        // hospitalId:user.deptPid,
        // deptId:user.deptId,
        hospitalId: teamsId,
        deptId: enertyId,
        status: 1,
      };
      if (this.edit) {
        obj.id = this.$route.query.id;
      }
      console.log(Qs.stringify(obj));
      const { data: res } = await this.$http({
        url: this.$http.adornUrl(
          this.edit ? "/programInfo/update" : "/programInfo/add"
        ),
        method: "post",
        data: Qs.stringify(obj),
      });
      // console.log(res,'save res');
      if (res.status) {
        this.tabs = "once";
        this.basicFlag = true;
        this.programInfoId = res.data.id;
        // this.entityId = res.data.id
      }
    },
    // 保存一次性方案活动信息
    async submit(index, mode) {
      // console.log(this.formList[index]);
      let form = Object.assign({}, this.formList[index]);
      this.$refs.form[index].validate(async (valid) => {
        if (valid) {
          if (form.activityType === 1 && form.medicineList.length === 0) {
            this.$message.error("主题内容不能为空！");
            return;
          }
          if (form.activityType === 4 && form.missionaryWorks.length === 0) {
            this.$message.error("主题内容不能为空！");
            return;
          }
          if (form.activityType === 5 && form.indexLibraries.length === 0) {
            this.$message.error("主题内容不能为空！");
            return;
          }
          let obj = {
            programInfoId: this.programInfoId,
            entityId: this.diseases,
            activityName: form.activityName,
            activityType: form.activityType,
            activityMode: mode,
            activityStart: form.activityStart,
            executionTime: form.executionTime,
            orderNum: form.orderNum,
            activityRemarks: form.activityRemarks,
            activityDescription: form.activityDescription,
            // id:form.id,
            // teamId:'7b46ed791ec34b7aa078abf6f4ce4ad3'
          };
          if (form.id) {
            obj.id = form.id;
          }
          if (form.medicineList && form.medicineList.length) {
            obj.drugsManageId = form.medicineList[0].id;
          }
          if (form.missionaryWorks && form.missionaryWorks.length) {
            obj.missionaryWorkId = form.missionaryWorks[0].item.id;
          }
          if (form.indexLibraries && form.indexLibraries.length) {
            obj.followUpId = form.indexLibraries[0].item.id;
          }
          // console.log(Qs.stringify(obj),'完成');
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(
              obj.id ? "/programActivity/update" : "/programActivity/add"
            ),
            method: "post",
            data:obj,
          });
          // console.log(res,'一次性res');
          if (res.status) {
            // this.$message.success(this.edit?'编辑方案活动成功':'添加方案活动成功')
            this.$message.success(res.msg);
            // -------这些操作的前提是没有再次拉取列表 所以才需要自己把编辑和删除的数据进行替换
            // 添加完马上把id放回去 这样再次修改就是编辑
            // this.formList[index].id = res.data.id
            // // 如果是编辑的话则不能直接push 而是替换 没做
            // if(obj.id){
            //   let arr = this.allList
            //   let item = arr.findIndex((v)=>v.id==obj.id)
            //   // console.log(item);
            //   this.$set(this.allList,item,res.data)
            // }else{
            //   this.allList.push(res.data)
            // }
            // this.formList[index].show = false
            // this.formList[index].leftShow = true
            //--------重新拉取列表即可实现
            this.getActivityList();
          } else {
            this.$message.error(res.msg);
          }
        } else {
          return false;
        }
      });
    },
    async getDiseasesList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '基础病种',
		
		},
      
      });
      // console.log(res,'res')
      this.diseasesList = res.data;
    },
    async getTypeList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '方案活动类型',
		
		},
       
      });
      // console.log(res,'res')
      this.typeList = res.data;
    },
    addMainCnt(form, index) {
      const type = form.activityType;
      if (type == 1) {
        form.dialog = true;
        // console.log(this.$refs,'refs click');
        let ref = `chooseMed${index}`;
        // let a = eval("this.$refs.chooseMed" +index)
        // console.log(ref,this.$refs[(ref)]);
        this.$nextTick(() => {
          this.$refs.chooseMed[index].init();
          // this.$refs.chooseMed[index].test();
          // this.$refs.chooseMed[index].visible=true;
          // console.log(this.$refs.chooseMed[index].init);
          // this.$refs[ref][0].init();
        });
      } else if (type == 4 || type == 5) {
        form.qsDialog = true;
        //  let ref = `chooseQs${index}`
        this.$nextTick(() => {
          // this.$refs.ref.init();
          this.$refs.chooseQs[index].init();
        });
      }
    },
    getMed(val, index) {
      this.formList[index].flag = true;
      this.formList[index].medicineList = [val];
    },
    getOther(val, index) {
      this.formList[index].flag = true;
      if (this.formList[index].activityType === 4) {
        this.formList[index].missionaryWorks = [
          { workTypeName: val.type.name, workName: val.item.workName },
        ];
      } else {
        this.formList[index].indexLibraries = [
          { libraryTypeName: val.type.name, libraryName: val.item.libraryName },
        ];
      }
    },
    deleteMed(row, index) {
      this.formList[index].flag = false;
      if (this.formList[index].activityType === 1) {
        this.formList[index].medicineList = [];
      } else if (this.formList[index].activityType === 4) {
        this.formList[index].missionaryWorks = [];
      } else {
        this.formList[index].indexLibraries = [];
      }
    },
    cancelActivity(index) {
      // 新增的则删除 否则就隐藏
      if (this.formList[index].new && this.edit) {
        this.formList.splice(index, 1);
      } else {
        this.formList[index].show = false;
      }
    },
    cancelActivity2(index) {
      // 新增的则删除 否则就隐藏
      if (this.circleList[index].new && this.edit) {
        this.circleList.splice(index, 1);
      } else {
        this.circleList[index].show = false;
      }
    },
    showRight(row) {
      this.clickSortList.unshift(row);
      this.clickSortList = [...new Set(this.clickSortList)]
      // this.formList[index].show = true
      // this.formList.forEach((v, i) => {
      //   if (i == index) {
      //     v.show = true;
      //   } else {
      //     // v.show = false
      //   }
      // });
    },
    showRight2(row) {
      this.clickSortList2.unshift(row);
      this.clickSortList2 = [...new Set(this.clickSortList2)]
      // this.formList[index].show = true
      // this.circleList.forEach((v, i) => {
      //   if (i == index) {
      //     v.show = true;
      //   } else {
      //     // v.show = false
      //   }
      // });
    },
    circlePush(data) {
      //不再是push而是重新拉取列表实现
      console.log(data, "circle push");
      // 如果是编辑的话则不能直接push 而是替换 没做
      // this.allList.push(data)
      // if(data.id){
      //         let arr = this.allList
      //         let item = arr.findIndex((v)=>v.id==data.id)
      //         this.$set(this.allList,0,res.data)
      //       }else{
      //         this.allList.push(res.data)
      // }
      this.getActivityList();
    },
    showPre() {
      this.$refs.preview.init();
      this.preview = true;
    },
    handleTab(activeName, oldActiveName) {
      // console.log(activeName,oldActiveName,1);
      // if(!this.basicFlag&&!this.edit&&oldActiveName=='basic'){
      //     this.$message.error('请保存基本信息！')
      //     return false
      // }
      // if(this.edit&&this.basicFlag&&activeName=='basic'){
      // }else{
      //   return false
      // }
      // if(!this.edit&&!this.basicFlag&&activeName=='once'||activeName=='circle'){
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-add {
  position: relative;
  // .search-list {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   .el-form{
  //     width: 20%;
  //   }
  // }
  .once {
    display: flex;
    .line {
      margin-top: 35px;
      width: 100%;
      height: 1px;
      background-color: #eee;
      margin-bottom: 20px;
    }
    .content {
      padding: 0 20px;
    }
    .lie {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 20px;
    }
    .main-cnt {
      padding: 0 10px;
      .title {
        display: flex;
        padding-bottom: 10px;
        .shu {
          width: 4px;
          height: 20px;
          background: #fbb933;
          border-radius: 5px;
        }
        .text {
          color: #fbb933;
          line-height: 20px;
          margin-left: 10px;
        }
      }
      .cnt-input {
        width: 100%;
        border-top: 1px dotted #4286f1;
        padding-top: 20px;
      }
      .other-type {
        width: 100%;
        height: 40px;
        border: 1px dotted #4286f1;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        color: #4286f1;
        cursor: pointer;
      }
    }
  }
  .left {
    margin-left: 5px;
    width: 15%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    .card {
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-height: 50px;
      padding: 0 10px;
      font-size: 14px;
      line-height: 50px;
      cursor: pointer;
      margin-bottom: 20px;
      border: 1px solid #eee;
      border-radius: 5px;
      .text {
        width: 80%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .card:hover {
      box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .right {
    width: 70%;
  }

  .wd100 {
    width: 100px;
  }
  .wd200 {
    width: 200px;
  }
  .wd300 {
    width: 300px;
  }
  .form {
    padding-bottom: 20px;
    width: 100%;
    height: auto;
    border: 1px solid #eee;
    margin-bottom: 20px;
    box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
      0 2px 4px 0 rgba(232, 237, 250, 0.5);
    // padding-bottom: 20px;
  }
  .form:first-child {
    margin-top: 0;
  }
  .el-tabs__content {
    overflow: unset;
  }
  .preview {
    position: absolute;
    top: -10px;
    right: 0;
    z-index: 10;
  }
  .circle {
    display: flex;
    .line {
      margin-top: 35px;
      width: 100%;
      height: 1px;
      background-color: #eee;
      margin-bottom: 20px;
    }
    .content {
      padding: 0 20px;
    }
    .lie {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 20px;
    }
    .main-cnt {
      padding: 0 10px;
      .title {
        display: flex;
        padding-bottom: 10px;
        .shu {
          width: 4px;
          height: 20px;
          background: #fbb933;
          border-radius: 5px;
        }
        .text {
          color: #fbb933;
          line-height: 20px;
          margin-left: 10px;
        }
      }
      .cnt-input {
        width: 100%;
        border-top: 1px dotted #4286f1;
        padding-top: 20px;
      }
      .other-type {
        width: 100%;
        height: 40px;
        border: 1px dotted #4286f1;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        color: #4286f1;
        cursor: pointer;
      }
    }
    .left {
      margin-left: 5px;
      width: 15%;
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      .card {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-height: 50px;
        padding: 0 10px;
        font-size: 14px;
        line-height: 50px;
        cursor: pointer;
        margin-bottom: 20px;
        border: 1px solid #eee;
        border-radius: 5px;
        .text {
          width: 80%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .card:hover {
        box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.1);
      }
    }
    .right {
      width: 70%;
    }
    .wd100 {
      width: 100px;
    }
    .wd200 {
      width: 200px;
    }
    .wd300 {
      width: 300px;
    }
    .form {
      width: 100%;
      height: auto;
      border: 1px solid #eee;
      margin-top: 20px;
      box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
        0 2px 4px 0 rgba(232, 237, 250, 0.5);
      // padding-bottom: 20px;
    }
    .form:first-child {
      margin-top: 0;
    }
  }
}
</style>
